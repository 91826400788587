import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { clearIncomeOrder } from "src/services/clearIncomeOrder";

import { parseJsonOrString } from "src/shared/helpers/parseJsonOrString";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Button/button";

import { ReactComponent as CheckIcon } from "src/images/check.svg";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { ReactComponent as CommentDots } from "src/images/message.svg";
import { ReactComponent as BestOrderIcon } from "src/images/best-order.svg";

import EtherOrderAddress from "./components/EtherOrderAddress";
import EtherOrderType from "./components/EtherOrderType";
import EtherServingAuto from "./components/EtherServingAuto";
import styles from "./styles.module.scss";
import { carClasses } from "src/shared/constants";
import { parseLocalStorageItem } from "src/services/localstorage/parseLocalStorageItem";

const EtherOrderItem = (props) => {
  const {
    declineIncomingOrder,
    acceptIncomingOrder,
    setDeletedEtherOrderIds,
    classes,
    acceptOrderLoading,
    order,
    isEfficiencySort,
    indexItem,
  } = props;

  const { t } = useTranslation();

  const {
    distance,
    comment,
    priceEstimated,
    quoteBonus,
    distancePriceForKilometre,
    destinationPoints,
    preOrderTime,
    id: orderId,
    carClassId,
    compensation,
    currency,
    paymentTypeId,
    distanceToOrder,
    startToFinish,
    polylinePositions,
  } = order;

  const classCarStyle = classes.find((carClass) => carClass.id === carClassId);
  const styleClass = classCarStyle
    ? {
      [carClasses.STANDARD]: 'economyHighlighting',
      [carClasses.COMFORT]: 'standardHighlighting',
      [carClasses.BUSINESS]: 'comfortHighlighting'
    }[classCarStyle.id]
    : null;

  const [swipePosition, setSwipePosition] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const navigate = useNavigate();

  const parsedComment = parseJsonOrString(comment);
  const orderNote = parsedComment?.note ?? parsedComment;
  const highDemandColor = order?.highDemandColor

  const handleAcceptOrder = async () => {
    const coordsDriver = JSON.parse(localStorage.getItem("coords"));
    const info = {
      orderId,
      lat: coordsDriver.latitude,
      lon: coordsDriver.longitude,
    };

    clearIncomeOrder();
    await acceptIncomingOrder(info);
    navigate("/order");
  };

  const declineOrder = async () => {
    await declineIncomingOrder(orderId, 2, null);
  };

  const handlersSwipe = useSwipeable({
    onSwiping: (eventData) => {
      const { deltaX, dir } = eventData;
      if (dir !== "Up" && dir !== "Down") {
        setSwipePosition(deltaX);
        setSwipeDirection(dir);
      }
    },
    onSwiped: async () => {
      setSwipeDirection(null);

      if (Math.abs(swipePosition) > window.innerWidth * 0.4 && !acceptOrderLoading) {
        if (swipeDirection === "Left") {
          const leftSwipePosition = -window.innerWidth;
          setSwipePosition(leftSwipePosition);
          await declineOrder();
          setTimeout(() => setDeletedEtherOrderIds(orderId), 200);
          return;
        } else if (swipeDirection === "Right") {
          const rightSwipePosition = window.innerWidth;
          setSwipePosition(rightSwipePosition);
          await handleAcceptOrder();
          return;
        }
      }

      setSwipePosition(0);
    },
  });

  const getButtonContent = () => {
    if (swipeDirection === "Left") {
      return <CloseIcon className={styles.icon} />;
    } else if (swipeDirection === "Right") {
      return <CheckIcon className={styles.icon} />;
    } else {
      return <>
        {isEfficiencySort && indexItem && <BestOrderIcon />}
        {t("accept_order")}
      </>
    }
  };

  const carClass = classes.find((classAuto) => classAuto.id === carClassId);

  const modsOrderItem = {
    [styles.swipeLeft]: swipeDirection === "Left",
    [styles.swipeRight]: swipeDirection === "Right",
  };

  const modsButton = {
    [styles.cancel]: swipeDirection === "Left",
    [styles.accept]: swipeDirection === "Right",
    [styles.preOrder]: Boolean(preOrderTime),
  };

  return (
    <div
      className={classNames(styles.EtherOrderItem, modsOrderItem, [styles.swipeable])}
      style={{
        transform: `translateX(${swipePosition}px)`,
      }}
      {...handlersSwipe}
    >
      <div
        className={`${styles.container} ${isEfficiencySort && indexItem
          ? styles.highlightClassName
          : styleClass
            ? styles[styleClass]
            : ""
          }`}
      >
        <EtherOrderType
          carClass={t(carClass?.name)}
          compensation={compensation}
          currency={currency}
          destinationPoints={destinationPoints}
          distance={distance}
          distancePriceForKilometre={distancePriceForKilometre}
          highDemandColor={highDemandColor}
          paymentTypeId={paymentTypeId}
          polylinePositions={polylinePositions}
          priceEstimated={priceEstimated}
          quoteBonus={quoteBonus}
          backgroundClass={styleClass}
        />
        <EtherOrderAddress
          destinationPoints={destinationPoints}
          distanceToOrder={distanceToOrder}
          startToFinish={startToFinish}
        />

        {comment && (
          <div className={styles.etherComment}>
            <CommentDots className={styles.etherCommentIcon} /> <p>{orderNote}</p>
          </div>
        )}
        {preOrderTime && <EtherServingAuto preOrderTime={preOrderTime} />}
        <Button
          className={classNames(styles.btn, modsButton, [])}
          disabled={acceptOrderLoading}
          fullWidth
          onClick={handleAcceptOrder}
        >
          {getButtonContent()}
        </Button>
      </div>
    </div>
  );
};

export default memo(EtherOrderItem);

import { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import useInterval from "src/hooks/useInterval";
import { getClasses } from "src/pages/AddCar/addcar.actions";
import BottomMenu from "src/pages/BottomMenu";
import { acceptIncomingOrder, declineIncomingOrder } from "src/pages/Order/order.actions";
import getRandomDelayMs from "src/services/getRandomDelayMs";
import { parseLocalStorageItem } from "src/services/localstorage/parseLocalStorageItem";
import ScrollableLayout from "src/shared/layouts/ScrollableLayout";
import CustomSnackbar from "src/shared/ui/CustomSnackbar";
import Divider from "src/shared/ui/Divider";

import EtherOrderHeaderInfo from "./components/EtherOrderHeaderInfo";
import EtherOrderItem from "./components/EtherOrderItem";
import EtherOrdersDataControl from "./components/EtherOrdersDataControl";
import EtherOrdersModal from "./components/EtherOrdersModal";
import { maxDelayInMs, minDelayInMs } from "./constants";
import {
  setDeletedEtherOrderIds,
  setEtherOrders,
  setFilterType,
  setActiveCarClassIds,
  setIsNewEtherOrders,
  setSortType,
  setTotalCountOrders,
} from "./store/etherActions";
import { getFilteredEtherOrders } from "./store/selectors/getFilteredEtherOrders";
import { fetchEtherOrders } from "./store/services/fetchEtherOrders";
import { parseJsonOrString } from "src/shared/helpers/parseJsonOrString";
import styles from "./styles.module.scss";

const userHasReadInstructions = parseLocalStorageItem("userHasReadInstructions");

const EtherOrdersPage = (props) => {
  const {
    atwork,
    filterType,
    sortType,
    setFilterType,
    setSortType,
    filteredEtherOrders,
    acceptIncomingOrder,
    declineIncomingOrder,
    setDeletedEtherOrderIds,
    getClasses,
    setTotalCountOrders,
    classes,
    isNewEtherOrders,
    setIsNewEtherOrders,
    fetchEtherOrders,
    etherOrderStatistics,
    acceptOrderLoading,
    activeCarClassIds,
    setActiveCarClassIds,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  // added entrance number to destinations points
  const filteredEtherOrdersWithEntrance = filteredEtherOrders.map((item, index) => {
    const comment = parseJsonOrString(item.comment);
    const entranceNumber = comment?.entrance || "";

    const destinationPointsWithEntrance = item.destinationPoints.map((destinationPoint, index) => {
      if (index === 0 && entranceNumber) {
        return {
          ...destinationPoint,
          mainText: `${destinationPoint.mainText || destinationPoint.name},  ${t("entrance")} ${entranceNumber}`,
        };
      }

      return destinationPoint;
    });

    return {
      ...item,
      destinationPoints: destinationPointsWithEntrance,
      indexItem: index < 1,
    };
  });

  const closeSnakbar = () => {
    setIsNewEtherOrders(false);
  };

  const randomDelayInMs = getRandomDelayMs(minDelayInMs, maxDelayInMs);
  useInterval(fetchEtherOrders, randomDelayInMs, true);

  useEffect(() => {
    setTotalCountOrders(filteredEtherOrders.length);
  }, [filteredEtherOrders.length, setTotalCountOrders]);

  useEffect(() => {
    if (!atwork) {
      return navigate("/orders");
    }
  }, [navigate, atwork]);

  useEffect(() => {
    const fetchData = async () => {
      await getClasses();
    };

    fetchData();
  }, [fetchEtherOrders, getClasses]);

  const ContentHeader = (
    <>
      <Trans i18nKey="live_header" values={{ count: filteredEtherOrders.length }} />
      <CustomSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        message={t("new_orders_added")}
        onClose={closeSnakbar}
        open={isNewEtherOrders}
        severity="info"
      />
      <EtherOrderHeaderInfo etherOrderStatistics={etherOrderStatistics} />
    </>
  );

  return (
    <ScrollableLayout
      bodyOptions={{
        pageLayoutClassname: styles.withoutPadding,
      }}
      headerOptions={{
        headerTitle: ContentHeader,
        showHeaderBackIcon: false,
      }}
    >
      <EtherOrdersDataControl
        filterType={filterType}
        setFilterType={setFilterType}
        setActiveCarClassIds={setActiveCarClassIds}
        activeCarClassIds={activeCarClassIds}
        setSortType={setSortType}
        sortType={sortType}
      />
      <Divider />
      <div className={styles.scrollableContainer}>
        {filteredEtherOrdersWithEntrance.length ? (
          filteredEtherOrdersWithEntrance.map((order) => (
            <EtherOrderItem
              acceptIncomingOrder={acceptIncomingOrder}
              acceptOrderLoading={acceptOrderLoading}
              classes={classes}
              declineIncomingOrder={declineIncomingOrder}
              key={order.id}
              order={order}
              isEfficiencySort={sortType?.sortBy === "efficiency"}
              indexItem={order.indexItem}
              setDeletedEtherOrderIds={setDeletedEtherOrderIds}
            />
          ))
        ) : (
          <h2 className={styles.noOrder}>{t("no_orders_available")}</h2>
        )}
      </div>
      {!userHasReadInstructions && <EtherOrdersModal />}
      <BottomMenu />
    </ScrollableLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    atwork: state.orders.workStatus,
    filterType: state.etherReducer.filterType,
    etherOrderStatistics: state.etherReducer.etherOrderStatistics,
    sortType: state.etherReducer.sortType,
    classes: state.addcar.classes,
    isNewEtherOrders: state.etherReducer.isNewEtherOrders,
    acceptOrderLoading: state.order.acceptOrderLoading,
    filteredEtherOrders: getFilteredEtherOrders(state),
    activeCarClassIds: state.etherReducer.activeCarClassIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEtherOrders: (data) => dispatch(fetchEtherOrders(data)),
    setEtherOrders: (data) => dispatch(setEtherOrders(data)),
    setIsNewEtherOrders: (isNew) => dispatch(setIsNewEtherOrders(isNew)),
    setFilterType: (filterType) => dispatch(setFilterType(filterType)),
    setSortType: (sortType) => dispatch(setSortType(sortType)),
    setDeletedEtherOrderIds: (orderId) => dispatch(setDeletedEtherOrderIds(orderId)),
    acceptIncomingOrder: (info) => dispatch(acceptIncomingOrder(info)),
    getClasses: () => dispatch(getClasses()),
    setTotalCountOrders: (count) => dispatch(setTotalCountOrders(count)),
    declineIncomingOrder: (orderId, reason, declineType) =>
      dispatch(declineIncomingOrder({ orderId, reason, declineType })),
    setActiveCarClassIds: (activeCarClassIds) => dispatch(setActiveCarClassIds(activeCarClassIds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EtherOrdersPage);

import { LANGUAGE_NAME } from "./const/localstorage";

export const paymentType = {
  0: "cash",
  1: "by_card",
  2: "cash_and_bonuses",
  4: "card_and_bonuses",
};

export const orderIconStages = {
  16: 3,
};

export const notRemovedItemsLocalStore = [LANGUAGE_NAME, "coords"];

/**Map */
export const mapViewNum = {
  "2D": "2D",
  "3D": "3D",
};

export const countryCode = {
  ua: "+380",
};
//external navigation providers
export const navProviders = {
  wego: "wego",
  waze: "waze",
  google: "google",
};

// Allow added car current time minus this time
export const allowCarYears = 24;

// countries
export const getLanguageCountry = (countryCode) => {
  switch (countryCode) {
    case "UK":
      return "uk";
    case "SK":
      return "sk";
    default:
      return "en";
  }
};

// check time for lost gps 60000ms = 1min
export const timeToCheckGpsSignal = 60000;

// Time in ms after the end of which the button for canceling the order will appear
export const ORDER_CANCELLATION_TIMEOUT_MS = 420000;

// status bar height if we dont have in local store
export const defaultStatusBarHeight = 30;
// pages which stop android preloader with inner logic
export const pagesStopCustomStopPreloader = ["/", "/orders", "/order"];

export const etherSortOptions = {
  ALL: "All",
  CASH: "Cash",
  CARD: "Card",
};

export const carClasses = {
  STANDARD: "78235900-0ce1-4aca-99ca-b89e776f0910",
  COMFORT: "b4c9b252-8d90-400c-bab8-c21f4fd9c7a9",
  BUSINESS: "bdf73206-ed08-4aec-b603-40ee948c53b2",
};

export const defaultCommission = 15;
export const preOrderTimeDifferenceInMinutes = 7;
export const pathsToSkipDriverInfoFetching = ["/dashboard"];
export const historyPageSize = 1000;
export const allowArriveRadius = 300;

export const imageDefaultQuality = {
  width: 500,
  height: 500,
  quality: 0.5,
};
export const allowArriveRadiusInMeters = 1000;
export const modalCloseDelay = 300;

export const checkRemoteLoggerDelay = 60000;

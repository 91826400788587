import { memo, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "src/shared/ui/Button/button";
import Icon from "src/shared/ui/Icon/Icon";
import { Modal } from "src/shared/ui/Modal";
import Text, { TextAlign, TextSize, TextWeight } from "src/shared/ui/Text";

import { showModalAlert } from "./modalAlert.actions";

import { ReactComponent as CheckCircleIcon } from "src/images/check-circle-icon.svg";

import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

const ModalAlert = (props) => {
  const { confirmHandler, completeIcon, mainAlertDesc, mainAlertText } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const showMAlert = useSelector((state) => state.modalAlert.showMAlert);
  const dispatch = useDispatch();

  const onClose = () => {
    const pushOrderAcceptedError = localStorage.getItem("pushOrderAcceptedError");

    if (pushOrderAcceptedError) {
      localStorage.removeItem("pushOrderAcceptedError");
    }

    dispatch(showModalAlert(false));

    if (confirmHandler) {
      confirmHandler();
    }
  };
  const goToSupport = () => {
    navigate("/contactus");
  };

  useEffect(() => {
    return () => {
      dispatch(showModalAlert(false));
    };
  }, []);

  return (
    <Modal isOpen={showMAlert} onClose={onClose}>
      <div className={styles.modalAlert}>
        {!completeIcon && <Icon Svg={CheckCircleIcon} className={styles.icon} />}
        <Text
          align={TextAlign.CENTER}
          size={TextSize.FONT_24}
          text={mainAlertText}
          weight={TextWeight.MEDIUM}
        />

        {!mainAlertDesc && (
          <Text
            align={TextAlign.CENTER}
            size={TextSize.FONT_16}
            text={mainAlertDesc}
            weight={TextWeight.LIGHT}
          />
        )}

        {mainAlertText === t("account_blocked") && (
          <Button onClick={goToSupport}>
            <Text
              className={styles.accountIsBlockedText}
              align={TextAlign.CENTER}
              size={TextSize.TEXT_14}
              text={t("chat_with_tech_support")}
              weight={TextWeight.MEDIUM}
            />
          </Button>
        )}

        <Button className={styles.closeBtn} onClick={onClose} variant="clear">
          <Text
            align={TextAlign.CENTER}
            size={TextSize.TEXT_14}
            text={t("close")}
            weight={TextWeight.MEDIUM}
          />
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ModalAlert);

import { createSelector } from "reselect";
import { filterablePaymentTypes } from "src/shared/const/payment";
import { carClasses, etherSortOptions } from "src/shared/constants";

const getEtherOrders = (state) => state.etherReducer.etherOrders;
const getFilterType = (state) => state.etherReducer.filterType;
const getSortType = (state) => state.etherReducer.sortType;
const getDeletedOrderIds = (state) => state.etherReducer.deletedEtherOrderIds;
const getActiveCarClassIds = (state) => state.etherReducer.activeCarClassIds;

const filterOrders = (orders, filterType, activeCarClassIds) => {

  const carClassSet = new Set(activeCarClassIds);

  return orders.filter((order) => {
    const matchesPaymentType =
      filterType === etherSortOptions.ALL ||
      filterablePaymentTypes[filterType].includes(order.paymentTypeId);

    const matchesCarClass = carClassSet.has(order.carClassId);

    return matchesPaymentType && matchesCarClass;
  });
};

const sortOrders = (orders, sortType) => {
  if (!sortType || !sortType.sortBy || !sortType.sortOrder) {
    return [...orders].sort((a, b) => a.distance - b.distance);
  }

  const { sortBy, sortOrder } = sortType;

  const sortedOrders = [...orders].map((order) => {
    let sortValueA;
    let sortValueB;

    if (sortBy === "efficiency") {
      sortValueA = order.priceEstimated / Math.pow(((order.distanceToOrder + order.startToFinish)/1000), 0.75);
      return { ...order, sortValue: sortValueA };
    } else {
      sortValueA = order[sortBy];
      return { ...order, sortValue: sortValueA };
    }
  }).sort((a, b) => {
    const sortValueA = a.sortValue;
    const sortValueB = b.sortValue;

    return sortOrder === "asc" ? sortValueA - sortValueB : sortValueB - sortValueA;
  });

  return sortedOrders;
};


const filterDeletedOrders = (orders, deletedIds) => {
  return orders.filter((order) => !deletedIds.includes(order.id));
};

export const getFilteredEtherOrders = createSelector(
  [getEtherOrders, getFilterType, getSortType, getDeletedOrderIds, getActiveCarClassIds],
  (etherOrders, filterType, sortType, deletedEtherOrderIds, activeCarClassIds) => {
    const filteredOrders = filterOrders(etherOrders, filterType, activeCarClassIds);
    const sortedOrders = sortOrders(filteredOrders, sortType);
    const visibleOrders = filterDeletedOrders(sortedOrders, deletedEtherOrderIds);

    return visibleOrders;
  },
);

import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styles from "./styles.module.scss";
import { ReactComponent as AutoAcceptDisabledIcon } from "src/images/auto-accept-disabled.svg";
import { ReactComponent as AutoAcceptEnabledIcon } from "src/images/auto-accept-enabled.svg";
import useClickOutside from "src/hooks/useClickOutside";
import AutoAcceptDisabledPopup from "../AutoAcceptPopup";

import useAutoAcceptDisabledTimer from "src/pages/Order/hooks/useAutoAcceptDisabledTimer";

const AutoAcceptDisabledButton = ({ autoAcceptDisabled, toggleAutoAccept }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useClickOutside(() => setIsPopupVisible(false));

  const { formattedTime, isTimerEnabled, count } = useAutoAcceptDisabledTimer();

  const prevCountRef = useRef(count);
  const toggleAutoAcceptRequest = async (state) => {
    try {
      await toggleAutoAccept(state);
      setIsPopupVisible(false);
    } catch (error) {
      console.error("Error while toggling auto-accept:", error);
    }
  };

  const handleConfirm = async (minutes) => {
    if (!minutes) {
      toggleAutoAcceptRequest(!autoAcceptDisabled);
      localStorage.removeItem("autoAcceptDisableUntil");
    } else {
      const targetTime = moment
        .utc(localStorage.getItem("autoAcceptDisableUntil") || moment.utc())
        .add(minutes, "minutes")
        .toISOString();
      localStorage.setItem("autoAcceptDisableUntil", targetTime);
      toggleAutoAcceptRequest(true);
    }
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    if (prevCountRef.current > 0 && count === 0) {
      toggleAutoAcceptRequest(false);
      localStorage.removeItem("autoAcceptDisableUntil");
    }
    prevCountRef.current = count;
  }, [count]);

  return (
    <>
      <button onClick={() => setIsPopupVisible(true)} className={styles.button}>
        {autoAcceptDisabled ? <AutoAcceptDisabledIcon /> : <AutoAcceptEnabledIcon />}
      </button>
      <AutoAcceptDisabledPopup
        formattedTime={formattedTime}
        showPopup={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        autoAcceptDisabled={autoAcceptDisabled}
        ref={popupRef}
      />
    </>
  );
};

export default AutoAcceptDisabledButton;

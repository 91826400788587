import { useEffect, useState } from "react";
import { ReactComponent as DistanceIcon } from "src/images/distance-icon.svg";
import { ReactComponent as DistanceIconDown } from "src/images/distance-icon-down.svg";
import { ReactComponent as BestOrderIcon } from "src/images/best-order.svg";
import { ReactComponent as MoneyCard } from "src/images/money-card.svg";
import { ReactComponent as MoneyCash } from "src/images/money-cash.svg";
import { ReactComponent as PriceIcon } from "src/images/price-icon.svg";
import { ReactComponent as PriceIconUp } from "src/images/price-icon-up.svg";
import SortingButton from "./components/SortingButton";
import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";
import { etherSortOptions, carClasses } from "src/shared/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const sortingButtonsData = [
  { type: etherSortOptions.ALL, Icon: null, label: "all" },
  { type: etherSortOptions.CASH, Icon: MoneyCash, label: "cash" },
  { type: etherSortOptions.CARD, Icon: MoneyCard, label: "card" },
];

const carClassFilterButtonsSet = new Set([
  { type: carClasses.STANDARD, label: "standard", backgroundClass: 'economyHighlighting' },
  { type: carClasses.COMFORT, label: "comfort", backgroundClass: 'standardHighlighting' },
  { type: carClasses.BUSINESS, label: "business", backgroundClass: 'comfortHighlighting' },
]);
const EtherOrdersDataControl = ({
  setFilterType,
  setActiveCarClassIds,
  setSortType,
  filterType,
  activeCarClassIds,
  sortType,
}) => {
  const { t } = useTranslation();
  const avtos = useSelector((state) => state.cars.avtos);
  const defaultCar = avtos.find((car) => car.isDefault);
  const defaultCarClassId = defaultCar ? defaultCar.classId : null;

  const [isActiveAllButton, setIsActiveAllButton] = useState(() => {
    return activeCarClassIds.size === carClassFilterButtonsSet.size;
  });

  const handleFilter = (filter) => {
    setFilterType(filter);
  };

  const handleSort = (sort) => {
    const sortOrder = sort === "efficiency" 
      ? "desc" 
      : sortType.sortOrder === "asc" 
        ? "desc" 
        : "asc";
  
    setSortType({ sortBy: sort, sortOrder });
  };

  const handleClickAllButton = () => {
    if (isActiveAllButton) {
      return;
    } else {
      const allClasses = [...carClassFilterButtonsSet].map((button) => button.type);
      setActiveCarClassIds(allClasses);
    }
    setIsActiveAllButton((prev) => !prev);
  };

  const handleCarClassFilter = (carClassId) => {
    const updatedCarClassIds = activeCarClassIds;

    if (updatedCarClassIds.has(carClassId) && updatedCarClassIds.size === 1) {
      return;
    }

    if (updatedCarClassIds.has(carClassId)) {
      updatedCarClassIds.delete(carClassId);
    } else {
      updatedCarClassIds.add(carClassId);
    }

    setIsActiveAllButton(updatedCarClassIds.size === carClassFilterButtonsSet.size);

    setActiveCarClassIds(updatedCarClassIds);
  };

  useEffect(() => {
    const updateLocalStorage = () => {
      const dataToSave = {
        filterType,
        activeCarClassIds: Array.from(activeCarClassIds),
        sortType,
      };
      localStorage.setItem("orderEtherFilters", JSON.stringify(dataToSave));
    };
    updateLocalStorage();
  }, [filterType, activeCarClassIds, sortType]);

  const filteredCarClassButtons = [...carClassFilterButtonsSet].filter(
    (button) => !(defaultCarClassId === carClasses.COMFORT && button.type === carClasses.BUSINESS)
  )

  return (
    <>
      {defaultCarClassId !== carClasses.STANDARD && (
        <div className={styles.sortingClassCar}>
          <div className={styles.leftSide}>
            <SortingButton
              active={isActiveAllButton}
              key="All"
              onClick={handleClickAllButton}
            >
              {t("all")}
            </SortingButton>
            {filteredCarClassButtons.map((buttonProps) => (
              <SortingButton
                key={buttonProps.type}
                active={activeCarClassIds.has(buttonProps.type)}
                onClick={() => handleCarClassFilter(buttonProps.type)}
                backgroundClass={buttonProps.backgroundClass}
              >
                {t(buttonProps.label)}
              </SortingButton>
            ))}
          </div>
        </div>
      )}


      <div className={styles.EtherOrdersDataControl}>
        <div className={styles.leftSide}>
        <BestOrderIcon
            className={classNames(
              styles.sortIcon,
              {
                [styles.sortByEfficiencyAsc]: sortType.sortBy === "efficiency",
               },
              []
            )}
            onClick={() => handleSort("efficiency")}
          />
          {/*           {sortingButtonsData.map((buttonProps) => (
            <SortingButton
              Icon={buttonProps.Icon}
              active={filterType === buttonProps.type}
              key={buttonProps.type}
              onClick={() => handleFilter(buttonProps.type)}
            >
              {t(buttonProps.label)}
            </SortingButton>
          ))} */}
        </div>

        <div className={styles.rightSide}>

          {sortType.sortBy === "distanceToOrder" && sortType.sortOrder === "asc" ? (
            <DistanceIconDown
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByDistanceAsc]: sortType.sortBy === "distanceToOrder",
                },
                []
              )}
              onClick={() => handleSort("distanceToOrder")}
            />
          ) : (
            <DistanceIcon
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByDistanceDesc]: sortType.sortBy === "distanceToOrder",
                },
                []
              )}
              onClick={() => handleSort("distanceToOrder")}
            />
          )}
          {sortType.sortBy === "priceEstimated" && sortType.sortOrder === "asc" ? (
          <PriceIconUp
            className={classNames(
              styles.sortIcon,
              {
                [styles.sortByPriceAsc]: sortType.sortBy === "priceEstimated",
              },
              [],
            )}
            onClick={() => handleSort("priceEstimated")}
          />
        ) : (
            <PriceIcon
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByPriceDesc]: sortType.sortBy === "priceEstimated",
                },
                [],
              )}
              onClick={() => handleSort("priceEstimated")}
            />
        )}
        </div>

      </div>
    </>
  );
};

export default EtherOrdersDataControl;

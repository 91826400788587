import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import useCountdown from "src/hooks/useCountdown";
import { t } from "i18next";
import { formatMillisecondsAsTime } from "src/pages/Order/helpers/formatMillisecondsAsTime";


const useAutoAcceptDisabledTimer = () => {

  const getRemainingTime = () => {
    const storedTime = localStorage.getItem("autoAcceptDisableUntil");
    const targetDateTime = storedTime && moment(storedTime).isValid()
      ? moment.utc(storedTime).valueOf()
      : null;
    return targetDateTime ? Math.max(targetDateTime - moment.utc(), 0) : 0;
  };

  const [remainingTimeInMs, setRemainingTimeInMs] = useState(getRemainingTime());

  const countdownStart = Math.max(Math.floor(remainingTimeInMs / 1000), 0);
  const { count } = useCountdown({ countStart: countdownStart });
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = getRemainingTime();
      if (newRemainingTime !== remainingTimeInMs) {
        setRemainingTimeInMs(newRemainingTime);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTimeInMs]);

  const [formattedTime, isTimerEnabled] = useMemo(() => {
    if (remainingTimeInMs === 0 || count <= 0) {
      return [null, false];
    }

    const timeLeft = formatMillisecondsAsTime(count * 1000);
    return [t("enable_in_auto_accept", { time: timeLeft }), true];
  }, [count, remainingTimeInMs]);

  return { formattedTime, isTimerEnabled, count };
};

export default useAutoAcceptDisabledTimer;

